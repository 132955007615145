import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { graphql } from "gatsby";

const SimplePageSingle = ({ data }) => {
	const { contentfulSimplePage } = data;
	return (
		<Layout>
			<Helmet>
				<title>Agoric - {contentfulSimplePage.title}</title>
			</Helmet>
			<main className={contentfulSimplePage.slug}>
				<section className='mb-5 pb-5'>
					<div className='container'>
						<div className='row title'>
							<div className='col'>
								<h1>{contentfulSimplePage.title}</h1>
							</div>
						</div>

						<div className='row'>
							<div className='col-12'>
								<div className='text-content'>{renderRichText(contentfulSimplePage.body)}</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};

export default SimplePageSingle;

export const query = graphql`
	query SimplePageBySlug($slug: String!) {
		contentfulSimplePage(slug: { eq: $slug }) {
			id
			title
			slug
			body {
				raw
			}
		}
	}
`;
